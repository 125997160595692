import React, { useEffect, useState } from 'react'
import SearchBar from './SearchBar';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { SiteData } from '../../utilities/SiteData';

function SearchWebsite() {
    const [search, setSearch] = useState("");
    const [results, setResults] = useState([]);
    const navigate = useNavigate();
    const currentTheme = useSelector(state => state.ui.theme);

    useEffect(() => {
        if (search.trim() && search.length >= 3) {
            const filteredResults = SiteData.filter((item) =>
                item.title.toLowerCase().includes(search.toLowerCase()) ||
                item.content.toLowerCase().includes(search.toLowerCase())
            );
            setResults(filteredResults);
        } else {
            setResults([]);
        }
    }, [search]);

    const handleResultClick = (url) => {
        navigate(url);
        setResults([]);
        setSearch("")

    };

    return (
        <div className='text-xs text-black w-fit z-50'>
            <SearchBar
                value={search}
                placeholder="Search Site"
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => setSearch("")}
            />
            {results.length > 0 && (
                <div className={`absolute rounded-xl backdrop-blur-xl w-60 ml-2 border-black shadow-2xl
                    ${currentTheme === "light" ? "bg-[#f2f8ff9f] backdrop-brightness-95" : "bg-[#09335394] text-white backdrop-brightness-150"}`
                }>
                    {results.map((item) => (
                        <NavLink
                            to={item.url}
                            className={
                                `block p-3 rounded-xl font-extralight hover:shadow-lg
                                    ${currentTheme === "light" ? "hover:bg-gray-200" : "hover:bg-[#093353]"}`
                            }
                            key={item.id} onClick={() => handleResultClick(item.url)}
                        >
                            <h2 className={`text-2xl ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}>{item.title}</h2>
                            <p className="text-base">{item.content}</p>
                        </NavLink>
                    ))}
                </div>
            )}
        </div>
    );
}

export default SearchWebsite