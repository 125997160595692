import React from 'react'
import { useSelector } from 'react-redux';
import AppStoreButtons from "../../media/CashbackApp/AppStoreButtons.svg"
import AppHomePage from "../../media/CashbackApp/AppHomeScreen.svg"

function CentexCashbackApp() {
    const currentTheme = useSelector(state => state.ui.theme);

    return (
        <section
            id="centexCashbackAppSection"
            className={`section py-24 relative lg:flex 
        ${currentTheme === "light"
                    ? "bg-gradient-to-br from-[#ffffff00] to-[#B9D6E9]"
                    : "bg-gradient-to-br from-[#07284100] to-[#072841]"
                }`}
        >
            <div className=''>
                {/* Title */}
                <div className='px-8 lg:px-28 text-center md:text-left'>
                    <h2
                        className={`text-4xl lg:text-6xl font-extrabold mb-4
                        ${currentTheme === "light"
                                ? "centexTextColour"
                                : "centexOrangeText"
                            }`}
                    >
                        Say hello to the new <br />
                        Centex Cashback App!
                    </h2>

                    {/* Description */}
                    <p className="mb-8 text-xl lg:text-3xl max-w-xl">
                        We're excited to introduce more savings, great new features, and our
                        <strong> NEW Centex Cashback Sweepstakes!!!</strong> Download it now via
                        the links below to get started.
                    </p>

                    {/* App Store Buttons */}
                    <img src={AppStoreButtons} className="w-fit mb-6" />
                </div>

                {/* More than just savings */}
                <div
                    className={`rounded-2xl shadow-lg p-6 lg:p-20 mt-20 lg:-mr-72
                    ${currentTheme === "light"
                            ? "bg-gradient-to-r from-[#ffffff00] to-[#B9D6E9]"
                            : "bg-gradient-to-r from-[#07284100] to-[#072841]"
                        }`}
                >
                    <h2
                        className={`text-4xl lg:text-6xl font-extrabold mb-6 
                        ${currentTheme === "light"
                                ? "centexTextColour"
                                : "centexOrangeText"
                            }`}
                    >
                        More than just savings
                    </h2>
                    <ul className={`text-xl lg:text-3xl font-light list-disc ml-6 pr-16 lg:pr-56
                    ${currentTheme === "light" && "centexTextColour"}`}
                    >
                        <li className="py-2">Earn cashback on every fuel up, in-store purchase*, carwash & more.</li>
                        <li className="py-2">See your sweepstakes entries, and monthly winners.</li>
                        <li className="py-2">Easily check your cashback balance, transfer funds and find the nearest Centex Cashback locations.</li>
                    </ul>
                </div>
            </div>

            {/* Phone App Image */}
            <div className="lg:w-1/2 flex justify-center lg:justify-end mt-10 lg:mt-0 lg:mr-14">
                <img
                    src={AppHomePage}
                    className="h-[300px] lg:h-[900px] w-auto"
                    alt="Centex Cashback App preview"
                />
            </div>
        </section>
    )
}

export default CentexCashbackApp