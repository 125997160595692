export const SiteData = [
    { id: 1, title: 'Home', content: 'Explore Centex Petroleum\'s Offerings and Services.', url: '/' },
    { id: 2, title: 'Loyalty Programs', content: 'Centex Fuel Pass & Cashback Card - Save on Fuel and More.', url: '/#loyaltyProgramsSection' },
    { id: 3, title: 'Our Locations', content: 'Find Over 150 Convenient Locations Across Canada.', url: '/#locationsSection' },
    { id: 4, title: 'Car Wash', content: 'Lean about our Premium Car Wash Services.', url: '/#carwashSection' },
    { id: 5, title: 'First Nations Partnership', content: 'Empowering First Nations Through Strategic Partnerships.', url: '/firstnations/' },
    { id: 6, title: 'Go Market', content: 'Your One-Stop Convenience Store Destination.', url: '/gomarket/' },
    { id: 7, title: 'About Us', content: 'Learn About Centex and Our Mission.', url: '/about/' },
    { id: 8, title: 'Our History', content: 'Over 30 Years of Excellence and Growth.', url: '/about/history/' },
    { id: 9, title: 'Community Support', content: 'Our Commitment to Supporting Communities Worldwide.', url: '/about/communities/' },
    { id: 10, title: 'Opportunities', content: 'Explore Career and Dealership Opportunities with Centex.', url: '/Opportunities/' },
    { id: 11, title: 'Cashback Card', content: 'Earn Rewards on Every Fuel and In-Store Purchase.', url: '/cashback/' },
    { id: 12, title: 'Fuel Pass Program', content: 'Simplify Fleet Fuel Management with Our Tailored Program.', url: '/fuelprogram/' },
    { id: 13, title: 'Centex Car Wash Locations', content: 'Find a car wash near you.', url: '/locations/#carwash' },
    { id: 14, title: 'All Centex Locations', content: 'Find a Centex Gas Station near you.', url: '/locations/' },
    { id: 15, title: 'Communities: Poverty Relief Africa', content: 'Poverty Relief Africa has the mission to relieve the physical, emotional and intellectual poverty facing children and women in war-torn northern Uganda', url: '/about/communities/#PovertyReliefAfrica' },
    { id: 16, title: 'Communities: Malawi Water Project', content: 'goal of the MALAWI WATER PROJECT is to improve health and alleviate suffering', url: '/about/communities/#MalawiWaterProject' },
    { id: 17, title: 'Communities: Mom2Mom Global', content: 'Mom2Mom Global is a worldwide network of breastfeeding support and advocacy for military families.', url: '/about/communities/#Mom2MomGlobal' },
    { id: 18, title: 'Communities: Child Haven International', content: 'Child Haven International is a registered not for profit charity founded in 1985.', url: '/about/communities/#ChildHavenInternational' },
    { id: 19, title: 'Communities: Umoja Community Mosaic', content: 'Soccer Without Boundaries operated as a small grassroots organization with a big impact on social inclusion.', url: '/about/communities/#UmojaCommunityMosaic' },
    { id: 20, title: 'Communities: The Child is Innocent', content: 'The Child is Innocent provides comprehensive educational scholarships to children from Northern Uganda', url: '/about/communities/#ChildIsInnocent' },
    { id: 21, title: 'What we Do', content: 'Learn About Centex and Our Mission.', url: '/about/' },
    { id: 22, title: 'Cashback App', content: 'Easily check your cashback balance, transfer funds and find the nearest Centex Cashback locations.', url: '/#centexCashbackAppSection' },
    { id: 23, title: 'Carwash Locations', content: 'Find a carwash newar you!', url: '/locations/#carwash' },
];